import React from "react"
import classes from "./Layout.module.css"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"

export default function Layout(props) {
  return (
    <div className={classes.Layout}>
      <Header />
      <main className={classes.Main}>{props.children}</main>
      <Footer />
    </div>
  )
}
