import React from 'react'
import classes from "./Games.module.css"
import { Helmet } from "react-helmet-async"
import ProjectList from "../../components/Projects/ProjectList/ProjectList"
import Project from "../../components/Projects/Project/Project"

export default function Games() {
  return (
    <div>
      <Helmet>
        <title>Maxtasy - Games</title>
      </Helmet>
      <h2>Games</h2>
      <p>
        A collection of games I made. Some of them are from tutorials or code-alongs.
      </p>
      <hr className={classes.Separator} />
      <ProjectList>
        <Project projectType="games" projectName="super-mario-bros" />
        <hr className={classes.Separator} />
        <Project projectType="games" projectName="chess" />
        <hr className={classes.Separator} />
        <Project projectType="games" projectName="flappy-bird" />
        <hr className={classes.Separator} />
        <Project projectType="games" projectName="minesweeper" />
        <hr className={classes.Separator} />
        <Project projectType="games" projectName="snake" />
        <hr className={classes.Separator} />
        <Project projectType="games" projectName="tetris" />
      </ProjectList>
    </div>
  )
}
