import React from 'react'
import classes from "./ProjectList.module.css"

export default function ProjectList(props) {
  return (
    <div className={classes.ProjectList}>
      {props.children}
    </div>
  )
}
