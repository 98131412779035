import React from 'react'
import projects from "../../../data/projects.json"

export default function Project(props) {
  const project = projects[props.projectType][props.projectName]
  let href;
  if (props.projectType === "programs") {
    href = project.githubRepo
  } else {
    href = project.demoUrl || `/projects/${props.projectName}/`
  }

  return (
    <div>
      <div>
        <a href={href} target="_blank" rel="noreferrer">{project.title}</a>
        <p>{project.description}</p>
      </div>
    </div>
  )
}
