import React from "react"
import classes from "./About.module.css"
import { Helmet } from "react-helmet-async"
import Portrait from "../../assets/images/default.png"

export default function About() {
  return (
    <div>
      <Helmet>
        <title>Maxtasy - About</title>
      </Helmet>
      <h2>About Me</h2>
      <img src={Portrait} className={classes.Portrait} alt="" />
      <p>
        I'm Max - aka Maxtasy, a self-taught Frontend Web Developer and computer
        game enthusiast. Always eager to learn and create new things.{" "}
      </p>
      <p>
        Check out my projects and{" "}
        <a href="https://github.com/Maxtasy">github profile</a> to see what I
        created so far.
      </p>
      <p>If you want to contact me feel free to send me an email.</p>
      <a href="mailto:contact@maxtasy.me">contact@maxtasy.me</a>
    </div>
  )
}
