import Layout from "./hoc/Layout/Layout"
import About from "./containers/About/About"
import Games from "./containers/Games/Games"
import Programs from "./containers/Programs/Programs"
import WebApps from "./containers/WebApps/WebApps.js"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route path="/about" component={About} title="About" />
          <Route path="/games" component={Games} />
          <Route path="/programs" component={Programs} />
          <Route path="/webapps" component={WebApps} />
          <Redirect from="/" to="/about" />
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

export default App
