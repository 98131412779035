import React from 'react'
import classes from "./WebApps.module.css"
import { Helmet } from "react-helmet-async"
import ProjectList from "../../components/Projects/ProjectList/ProjectList"
import Project from "../../components/Projects/Project/Project"

export default function WebApps() {
  return (
    <div>
      <Helmet>
        <title>Maxtasy - WebApps</title>
      </Helmet>
      <h2>Web Apps</h2>
      <p>
        A collection of Web Apps I made. Some of them are from tutorials or code-alongs.
      </p>
      <hr className={classes.Separator} />
      <ProjectList>
        <Project projectType="webapps" projectName="react-codepen-clone" />
        <hr className={classes.Separator} />
        <Project projectType="webapps" projectName="breaking-bad-cast" />
        <hr className={classes.Separator} />
        <Project projectType="webapps" projectName="weather-app" />
        <hr className={classes.Separator} />
        <Project projectType="webapps" projectName="mars-weather-app" />
        <hr className={classes.Separator} />
        <Project projectType="webapps" projectName="password-generator" />
        <hr className={classes.Separator} />
        <Project projectType="webapps" projectName="password-strength-meter" />
        <hr className={classes.Separator} />
        <Project projectType="webapps" projectName="breathe-and-relax-app" />
        <hr className={classes.Separator} />
        <Project projectType="webapps" projectName="countdown-timer" />
        <hr className={classes.Separator} />
        <Project projectType="webapps" projectName="piano" />
      </ProjectList>
    </div>
  )
}
