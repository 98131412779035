import React from 'react'
import classes from "./Projects.module.css"
import { Helmet } from "react-helmet-async"
import ProjectList from "../../components/Projects/ProjectList/ProjectList"
import Project from "../../components/Projects/Project/Project"

export default function Programs() {
  return (
    <div>
      <Helmet>
        <title>Maxtasy - Programs</title>
      </Helmet>
      <h2>Programs</h2>
      <p>
        A collection of programs I made.
      </p>
      <hr className={classes.Separator} />
      <ProjectList>
        <Project projectType="programs" projectName="melody" />
        <hr className={classes.Separator} />
        <Project projectType="programs" projectName="cricket" />
      </ProjectList>
    </div>
  )
}
