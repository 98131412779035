import React from "react"
import Logo from "../../assets/images/maxtasy_logo.png"
import classes from "./Header.module.css"
import { Link, NavLink } from "react-router-dom"

export default function Header() {
  return (
    <div className={classes.Header}>
      <Link to="/about"><img src={Logo} className={classes.Logo} alt="Logo" /></Link>
      <nav className={classes.Nav}>
        <NavLink 
          to="/about"
          className={classes.NavLink}
          activeClassName={classes.NavLinkActive}
        >
          About
        </NavLink>
        <NavLink 
          to="/games"
          className={classes.NavLink}
          activeClassName={classes.NavLinkActive}
        >
          Games
        </NavLink>
        <NavLink 
          to="/programs"
          className={classes.NavLink}
          activeClassName={classes.NavLinkActive}
        >
          Programs
        </NavLink>
        <NavLink 
          to="/webapps"
          className={classes.NavLink}
          activeClassName={classes.NavLinkActive}
        >
          Web Apps
        </NavLink>
      </nav>
    </div>
  )
}
